// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  apiURL: 'https://condor-csoto-vbwz3bkl3a-uc.a.run.app/api/',
  apiCondorURL: 'https://condor-api-vbwz3bkl3a-uc.a.run.app',
  nombreInstancia: 'csoto',
  entorno: 'PRODUCCION',
  boletaExpressHabilitada: false
};
